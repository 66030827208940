<template>
  <div id="imintelzhgd">
    <div class="cm-container">
      <!-- <breadcrumb></breadcrumb> -->
      <div style="margin:20px 0px 20px 0px">
        <img style="width:100%;" src="@/assets/img/banner/banner3.png"
             alt="">
      </div>
      <div class="line-c">
        <!-- <div class="ll-c">
          <img src="@/assets/img/cs/cs01.png" alt="" />
        </div> -->
        <div class="lr-c">
          <div class="lr-title">智慧园区管理体验平台</div>

          <div class="lrnl">

            智慧园区管理体验平台主要服务对象为园区，整体上分为集成管理、能耗管理、设备运维、三维可视化模块，集成模块包含报警管理、报表查询、模式管理、运行日志、用户管理、角色管理、公告管理、系统配置、排版管理、公告管理功能。平台可对园区完成基本信息、能耗、设备的管理，以及可视化模型的观看。
          </div>
        </div>
        <div class="ll-c">
          <img src="@/assets/img/common/智慧园区管理体验平台-封面.png"
               alt="" />
        </div>
      </div>

      <div class="cm-img">
        <div class="cm-img-list"
             v-for="(item, index) in ddList"
             :key="index">

          <div>{{ item.text }}</div>
          <div>{{item.a01}}</div>
          <div>
            <span @click="zhgdfn(item)">去查看</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { reactive, toRefs, onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { setStorage } from "@/js/common";
// import breadcrumb from "@/components/breadcrumb";
// import router from "../../router/index.js";
export default {
  setup () {
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      major: [
        { name: "全部", id: 1 },
        { name: "建筑工程", id: 2 },
        { name: "道桥工程", id: 3 },
        { name: "地下工程", id: 4 },
      ],
      majoractive: 1,
      jobs: [
        { name: "全部", id: 1 },
        { name: "施工员（测量员）", id: 2 },
        { name: "质量员", id: 3 },
        { name: "安全员", id: 4 },
        { name: "标准员", id: 5 },
        { name: "材料员", id: 6 },
        { name: "劳务员（预算员）", id: 7 },
        { name: "资料员", id: 8 },
      ],
      jobsactive: 1,
      ddList: [
        {
          poster: require("@/assets/img/cs/cs07.png"),
          id: 1,
          text: "项目总况",
          a01: "该模块展示：工程简介、工程图片、工程视频、现场环境状况、质量分析、安全分析、机具统计、项目大事记、塔机报警、计算机报警、卸料平台报警、用工管理、资料统计的信息，可以让施工现场管理者快速掌握当前项目的各项信息，发生紧急时间也会有相应的提示，可以达到快速报警、快速响应、快速解决等一系列流程。",
          a02: 'http://218.18.161.40:7001/BMAX/SBIM/Index'

        }

      ],
    });
    const setbclist = (arr) => {
      store.commit("Setbclist", arr);
      setStorage("bclist", arr);
    };
    const methods = {
      toDetail: (item, datas) => {
        console.log(item);
        if (datas == 1) {
          let arr = [
            {
              text: "智慧设计",
              nindex: "3",
              path: "/home/digitalDesign",
              back: false,
            },
            {
              text: "课程介绍",
              nindex: "",
              path: "",
              back: false,
            },
          ];
          setbclist(arr);
          store.commit("SetnarbarMenuActive", "3s");
          setStorage("narbarMenuActive", "3s");
          router.push("/home/digitalDesignDetail");
        }
      },
      // 跳转
      zhgdfn: (item) => {
        window.open(item.a02);

      },
      back: () => {
        store.commit("SetnarbarMenuActive", "2");
        setStorage("narbarMenuActive", "2");
        router.push("/home/courseManagement");
      },
    };
    onMounted(() => { });
    onUnmounted(() => { });
    // provide('popfalse',state.popfalse)
    return {
      ...toRefs(state),
      ...methods,
    };
  },
  // components: { breadcrumb },
};
</script>
<style lang="scss" scoped>
#imintelzhgd {
  height: 100%;
  position: relative;

  .cm-container {
    width: 1200px;
    margin: 0 auto;
    background: #f3f3f3;
    padding: 0 0 100px;
    margin-top: 20px;
    .line-c {
      background: #fff;
      padding: 20px;
      display: flex;
      .ll-c {
        width: 290px;
        height: 192px;
        img {
          width: 290px;
          height: 100%;
          object-fit: contain;
        }
      }
      .lr-c {
        padding: 0 0 0 20px;
        flex-grow: 1;
        .lr-title {
          line-height: 24px;
          margin: 0 0 2px;
          font-size: 25px;
          font-family: PangMenZhengDao;
          color: #333333;
        }
        .lrnl {
          height: 19px;
          font-size: 14px;
          color: #666666;
          line-height: 19px;
          padding: 10px 0;
          width: 54%;
          // border-bottom:1px solid #EEEEEE;
          .spline {
            display: inline-block;
            width: 1px;
            height: 9.5px;
            margin: 0 20px;
            background: #eee;
          }
          &:last-child {
            border: none;
          }
        }
      }
    }
    .nt {
      height: 20px;
      font-size: 15px;
      font-weight: bold;
      color: #555555;
      line-height: 20px;
      margin: 20px 0 14px;
    }
    .ntc {
      font-size: 14px;
      color: #555555;
      line-height: 19px;
      background: #fff;
      padding: 20px;
      .tl-c {
        padding: 0 0 0 15px;
        &.c01 {
          margin: 0 0 20px;
        }
        .tlol {
          position: relative;
          &::before {
            content: "";
            width: 4px;
            height: 4px;
            background: #333333;
            border-radius: 50%;
            position: absolute;
            left: -10px;
            top: 9px;
          }
        }
      }
    }
    .cm-img {
      display: flex;
      flex-wrap: wrap;
      margin: 20px 0 -20px;
      .cm-img-list {
        width: 100%;
        height: 92px;
        background: #fff;

        margin-bottom: 20px;
        box-sizing: border-box;
        position: relative;
        display: flex;
        align-items: center;
        img {
          width: 100%;
          height: 78%;
          display: inline-block;
        }
        div {
          &:nth-child(1) {
            width: 8%;
            height: 52px;
            background: rgba(17, 161, 247, 0.27);
            text-align: center;
            line-height: 52px;
            font-size: 18px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #333333;
            margin:0px 10px 0px 10px;
          }
          &:nth-child(2) {
            width: 82%;
            padding: 0 6px;
            box-sizing: border-box;
          }
          &:nth-child(3) {
            width: 8%;
            span {
              width: 82px;
              height: 40px;
              background: #11a1f7;
              color: #fff;
              display: inline-block;
              margin-right: 10px;
              box-sizing: border-box;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
          }
        }
        &:nth-child(3n) {
        }
      }
    }
  }
}
@media screen and (min-width: 1600px) {
  #imintelzhgd {
    .cm-container {
      width: 1400px;
      .cm-img {
      }
      .line-c {
        background: #fff;
        padding: 20px;
        display: flex;
        .ll-c {
          width: 338.33px;
          height: 224px;
        }
      }
    }
  }
}
</style>
